@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  @apply antialiased text-gray-800 bg-white;
}

/* Effets de glassmorphisme modernes */
.glass-card {
  @apply bg-white/70 backdrop-blur-sm border border-white/20;
}

.glass-dark {
  @apply bg-gray-900/70 backdrop-blur-sm border border-gray-800/20;
}

/* Animations modernes */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

/* Effets de survol élégants */
.hover-card {
  @apply transition-all duration-300 ease-out;
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.1);
}

/* Effets de gradient */
.gradient-text {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-800;
}

.gradient-bg-light {
  @apply bg-gradient-to-tr from-blue-50 via-white to-blue-50;
}

/* Styles de bordure modernes */
.border-gradient {
  border-image: linear-gradient(to right, #2563eb, #3b82f6) 1;
}

/* Styles de focus modernes */
.focus-ring-modern {
  @apply focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white;
}

/* Scrollbar personnalisée */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-blue-200 rounded-full hover:bg-blue-300;
}

/* Styles de texte modernes */
.heading-xl {
  @apply text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight;
}

.heading-lg {
  @apply text-3xl md:text-4xl font-bold tracking-tight;
}

.body-lg {
  @apply text-lg leading-relaxed text-gray-600;
}

/* Styles de carte modernes */
.card-modern {
  @apply rounded-3xl bg-white shadow-lg hover:shadow-xl transition-all duration-300;
}